/* Packages */
import React from 'react'
/* Imported Components */
import { Layout } from '../components'
/* Styles */
/* Component */
const NotFoundPage = () => (
  <Layout title='404: Not found'>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
/* Exports */
export default NotFoundPage
